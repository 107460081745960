<template>
  <div>
    <v-app-bar flat app color="white" light>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
        <div class="pr-2 pl-2">
          <v-icon color="primary">mdi-account</v-icon>
        </div>
        <v-toolbar-title><span style="color:#84264f; font-weight:bold"> Usuarios</span> / Añadir usuario administrador</v-toolbar-title>
      </v-app-bar>

      <v-alert v-if="error$.isError" type="error">
        {{ error$.message }}
      </v-alert>

      <v-snackbar
        v-model="snackbar"
      >
        Hay campos que faltan por seleccionar
        <v-btn
          color="pink"
          text
          @click="snackbar = false"
        >
          Vale
        </v-btn>
      </v-snackbar>

      <v-container fluid>
        <v-form
          ref="form"
          v-model="valid"
          :lazy-validation="lazy"
        >
          <v-layout class="mt-8 wrap">
            <v-flex xs12>
              <v-text-field v-model="user.first_name" background-color="white" label="Nombre" placeholder="Escribir nombre" outlined required :rules="[v => !!v || 'Campo requerido']"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="user.last_name" background-color="white" label="Apellido" placeholder="Escribir apellido" outlined required :rules="[v => !!v || 'Campo requerido']"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field v-model="user.email" background-color="white" label="Email" placeholder="Escribir email" outlined required :rules="[v => !!v || 'Campo requerido']"></v-text-field>
            </v-flex>
<!-- 
            <v-flex xs12>
              <v-select v-model="user.type" :items="userType" item-text="label" item-value="value" label="Tipo usuario" outlined required :rules="[v => !!v || 'Campo requerido']"></v-select>
            </v-flex>
 -->
            <v-flex xs12>
              <v-text-field type="password" v-model="user.password" background-color="white" label="Password" placeholder="Escribir password" outlined required :rules="[v => !!v || 'Campo requerido']"></v-text-field>
            </v-flex>

            <v-flex>
            <v-btn class="text-white" color="secondary" to="/usuarios" >
              <v-icon left dark>mdi-arrow-left</v-icon>
              Volver
            </v-btn>
              <v-btn class="ml-4" depressed color="success" @click="createUser()">Crear</v-btn>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
  </div>
</template>

<script>
import store from '../../store';
import { USERS_TYPE } from '../../constants';

export default {
  data() {
    return {
      user: {
        first_name: null,
        last_name: null,
        email: null,
        type: 'ADMIN',
        password: null
      },
      userType: USERS_TYPE,
      valid: false,
      lazy: true,
      snackbar: false
    }
  },
  computed: {
    error$() {
      return store.getters['users/error'];
    }
  },
  methods: {
    menu() {
      this.$store.dispatch('user/ToggleMenu');
    },
    createUser() {
      if (this.$refs.form.validate()) {
        this.valid = true;
        this.snackbar = false;
        this.$store.dispatch('users/CreateNewUser', { user: this.user })
          .then((data) => {
            if (data) this.$router.back();
          })
          .catch(() => {
            alert('No ha sido posible crear el usuario')
          })
      } else {
        this.valid = false;
        this.snackbar = true;
      }
    }
  }
}
</script>

<style>

</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { flat: "", app: "", color: "white", light: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.menu($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "pr-2 pl-2" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-account")
              ])
            ],
            1
          ),
          _c("v-toolbar-title", [
            _c(
              "span",
              { staticStyle: { color: "#84264f", "font-weight": "bold" } },
              [_vm._v(" Usuarios")]
            ),
            _vm._v(" / Añadir usuario administrador")
          ])
        ],
        1
      ),
      _vm.error$.isError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v("\n        " + _vm._s(_vm.error$.message) + "\n      ")
          ])
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _vm._v("\n        Hay campos que faltan por seleccionar\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", text: "" },
              on: {
                click: function($event) {
                  _vm.snackbar = false
                }
              }
            },
            [_vm._v("\n          Vale\n        ")]
          )
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": _vm.lazy },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "v-layout",
                { staticClass: "mt-8 wrap" },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "white",
                          label: "Nombre",
                          placeholder: "Escribir nombre",
                          outlined: "",
                          required: "",
                          rules: [
                            function(v) {
                              return !!v || "Campo requerido"
                            }
                          ]
                        },
                        model: {
                          value: _vm.user.first_name,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "first_name", $$v)
                          },
                          expression: "user.first_name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "white",
                          label: "Apellido",
                          placeholder: "Escribir apellido",
                          outlined: "",
                          required: "",
                          rules: [
                            function(v) {
                              return !!v || "Campo requerido"
                            }
                          ]
                        },
                        model: {
                          value: _vm.user.last_name,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "last_name", $$v)
                          },
                          expression: "user.last_name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          "background-color": "white",
                          label: "Email",
                          placeholder: "Escribir email",
                          outlined: "",
                          required: "",
                          rules: [
                            function(v) {
                              return !!v || "Campo requerido"
                            }
                          ]
                        },
                        model: {
                          value: _vm.user.email,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "email", $$v)
                          },
                          expression: "user.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          type: "password",
                          "background-color": "white",
                          label: "Password",
                          placeholder: "Escribir password",
                          outlined: "",
                          required: "",
                          rules: [
                            function(v) {
                              return !!v || "Campo requerido"
                            }
                          ]
                        },
                        model: {
                          value: _vm.user.password,
                          callback: function($$v) {
                            _vm.$set(_vm.user, "password", $$v)
                          },
                          expression: "user.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-white",
                          attrs: { color: "secondary", to: "/usuarios" }
                        },
                        [
                          _c("v-icon", { attrs: { left: "", dark: "" } }, [
                            _vm._v("mdi-arrow-left")
                          ]),
                          _vm._v("\n              Volver\n            ")
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-4",
                          attrs: { depressed: "", color: "success" },
                          on: {
                            click: function($event) {
                              return _vm.createUser()
                            }
                          }
                        },
                        [_vm._v("Crear")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
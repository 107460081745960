var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { flat: "", app: "", color: "white", light: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.menu($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "pr-2 pl-2" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-account")
              ])
            ],
            1
          ),
          _c("v-toolbar-title", [
            _c(
              "span",
              { staticStyle: { color: "#84264f", "font-weight": "bold" } },
              [_vm._v(" Usuarios")]
            ),
            _vm._v(" / editar usuario")
          ])
        ],
        1
      ),
      _vm.error$.isError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v("\n        " + _vm._s(_vm.error$.message) + "\n      ")
          ])
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          model: {
            value: _vm.updatedSuccess,
            callback: function($$v) {
              _vm.updatedSuccess = $$v
            },
            expression: "updatedSuccess"
          }
        },
        [
          _vm._v("\n      Usuario guardado correctamente\n        "),
          _c(
            "v-btn",
            {
              attrs: { color: "pink", text: "" },
              on: {
                click: function($event) {
                  _vm.updatedSuccess = false
                }
              }
            },
            [_vm._v("\n          Cerrar\n        ")]
          )
        ],
        1
      ),
      _vm.user
        ? _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "background-color": "white",
                      label: "Nombre",
                      outlined: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.updateUser()
                      }
                    },
                    model: {
                      value: _vm.user.first_name,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "first_name", $$v)
                      },
                      expression: "user.first_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      "background-color": "white",
                      label: "Apellido",
                      outlined: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.updateUser()
                      }
                    },
                    model: {
                      value: _vm.user.last_name,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "last_name", $$v)
                      },
                      expression: "user.last_name"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      disabled: "",
                      "background-color": "white",
                      label: "Email",
                      outlined: ""
                    },
                    model: {
                      value: _vm.user.email,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.userType,
                      "item-text": "label",
                      "item-value": "value",
                      label: "Tipo usuario",
                      outlined: ""
                    },
                    on: {
                      change: function($event) {
                        return _vm.updateUser()
                      }
                    },
                    model: {
                      value: _vm.user.type,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "type", $$v)
                      },
                      expression: "user.type"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-switch", {
                    staticClass: "ma-2",
                    attrs: { color: "primary", label: "Desayuno incluido" },
                    on: {
                      change: function($event) {
                        return _vm.updateUser()
                      }
                    },
                    model: {
                      value: _vm.user.breakfast_included,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "breakfast_included", $$v)
                      },
                      expression: "user.breakfast_included"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c("v-switch", {
                    staticClass: "ma-2",
                    attrs: { color: "primary", label: "Snack incluido" },
                    on: {
                      change: function($event) {
                        return _vm.updateUser()
                      }
                    },
                    model: {
                      value: _vm.user.snack_included,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "snack_included", $$v)
                      },
                      expression: "user.snack_included"
                    }
                  })
                ],
                1
              ),
              !_vm.user.allergen
                ? _c("v-flex", { staticClass: "mb-6", attrs: { xs12: "" } }, [
                    _c("div", [_vm._v(" El usuario no tiene alergias")])
                  ])
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "text-white",
                  attrs: { color: "secondary" },
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { left: "", dark: "" } }, [
                    _vm._v("mdi-arrow-left")
                  ]),
                  _vm._v("\n          Volver\n        ")
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <v-app-bar flat app color="white" light>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
      <div class="pr-2 pl-2">
        <v-icon color="primary">mdi-account</v-icon>
      </div>

      <v-toolbar-title style="color:#84264f; font-weight:bold">
        Usuarios
        <v-chip color="primary" class="ml-4">{{ totalNumUsers$ }}</v-chip>
      </v-toolbar-title>
      <v-spacer />
      <v-btn medium color="primary" to="/usuarios/nuevo"
        >Añadir administrador</v-btn
      >
    </v-app-bar>

    <v-alert v-if="error$.isError" type="error">{{ error$.message }}</v-alert>

    <v-container fluid>
      <v-dialog v-model="delDialog" max-width="290">
        <v-card>
          <v-card-title>Borrar</v-card-title>
          <v-card-text class="subtitle-1"
            >Deseas borrar este usuario ?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="delDialog = false"
              >Cancelar</v-btn
            >
            <v-btn color="primary" @click="deleteUser()">Sí</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="fixUserDialog" max-width="500">
        <v-card>
          <v-card-title>Arreglar usuario</v-card-title>
          <v-row justify="center">
            <v-col cols="10" sm="10">
              <v-text-field
                dense
                v-model="fixUser.id"
                label="Id usuario"
                disabled
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="10">
              <v-text-field
                dense
                v-model="fixUser.subscriptionId"
                label="Subscription ID"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="10">
              <v-text-field
                dense
                v-model="fixUser.customerId"
                label="Customer ID"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="10" sm="10">
              <v-select
                v-model="fixUser.vegan"
                :items="[
                  { text: 'Vegana', value: true },
                  { text: 'Flexible', value: false },
                ]"
                item-text="text"
                item-value="value"
                label="Tipo de dieta"
              ></v-select>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="
                fixUserDialog = false;
                fixUser.id = null;
                fixUser.subscriptionId = null;
                fixUser.customerId = null;
                fixUser.vegan = null;
              "
              >Cancelar</v-btn
            >
            <v-btn color="primary" @click="fixUserSelected()">Arreglar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="lockSubscriptionDialog" max-width="290">
        <v-card>
          <v-card-title>Borrar</v-card-title>
          <v-card-text class="subtitle-1"
            >¿Está seguro que desea realizar ésta acción?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="lockSubscriptionDialog = false"
              >Cancelar</v-btn
            >
            <v-btn color="primary" @click="lockSubscription()">Sí</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field
            dense
            v-model="searchUsers"
            @input="searchChange"
            prepend-inner-icon="mdi-magnify"
            background-color="red"
            label="Buscar usuario por email"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-toolbar color="secondary" dark flat dense>
        <v-row>
          <v-col cols="12" sm="3" class="text-center">
            <div class="body-1">Nombre</div>
          </v-col>

          <v-col cols="12" sm="1" class="text-center">
            <div class="body-1">Tipo</div>
          </v-col>
          <v-col cols="12" sm="1" class="text-center">
            <div class="body-1">Tipo Dieta</div>
          </v-col>
          <v-col cols="12" sm="2" class="text-center">
            <div class="body-1">Email</div>
          </v-col>
          <v-col cols="12" sm="1" class="text-center">
            <div class="body-1">Proveedor de pago</div>
          </v-col>
          <v-col cols="12" sm="1" class="text-center">
            <div class="body-1">Fecha de creación</div>
          </v-col>
          <v-col cols="12" sm="1" class="text-center">
            <div class="body-1">ID Pago</div>
          </v-col>
          <v-col cols="12" sm="2">
            <div class="body-1"></div>
          </v-col>
        </v-row>
      </v-toolbar>

      <ItemList
        :items="users$"
        :actions="[
          { type: 'VIEW_AS_USER', icon: 'mdi-account-switch' },
          { type: 'DELETE_USER', icon: 'mdi-delete' },
          { type: 'LOCK_PAYPAL', icon: 'mdi-lock' },
          { type: 'FIX_USER', icon: 'mdi-auto-fix' },
        ]"
        type="user"
        :dragAndDropActive="true"
        :totalItems="totalNumUsers$"
        @actionTrigger="onActionTrigger($event)"
        @paginationChange="paginationChange($event)"
      />
    </v-container>
  </div>
</template>

<script>
import store from "../../store";
import ItemList from "@/components/itemList";
import { TABLE_NUM_ITEMS } from "../../constants";
import { API, ApiKey } from "../../config";
import axios from "axios";
import qs from "qs";

export default {
  data() {
    return {
      lockSubscriptionDialog: false,
      delDialog: false,
      conditions: "",
      searchUsers: null,
      fixUserDialog: false,
      pageIndex: 0,
      fixUser: {
        id: null,
        customerId: null,
        subscriptionId: null,
        vegan: null,
      },
    };
  },
  components: { ItemList },
  computed: {
    users$() {
      return store.getters["users/items"];
    },
    totalNumUsers$() {
      return parseInt(store.getters["users/totalItems"]);
    },
    error$() {
      return store.getters["users/error"];
    },
  },
  methods: {
    menu() {
      store.dispatch("user/ToggleMenu");
    },
    onActionTrigger({ event }) {
      switch (event.action) {
        case "DELETE_USER":
          this.delDialog = true;
          this.itemIdToDelete = event.id;
          break;
        case "VIEW_AS_USER":
          this.openFrontendAsUser(event.id);
          break;
        case "FIX_USER":
          this.fixUserDialog = true;
          this.fixUser.id = event.id;
          this.fixUser.vegan = event.vegan;
          break;
        case "LOCK_PAYPAL":
          this.lockSubscriptionDialog = true;
          this.itemIdToDelete = event;
          break;
      }
    },
    async fixUserSelected() {
      const headerAPI = { ApiKey: `${ApiKey}` };
      await axios.post(
        `${API}/api/users/repair/${this.fixUser.id}`,
        qs.stringify({
          stripe_customer_id: this.fixUser.customerId,
          stripe_subscription_id: this.fixUser.subscriptionId,
          vegan: this.fixUser.vegan,
        }),
        {
          headers: { ...headerAPI },
        }
      );
      this.fixUserDialog = false;
      this.fixUser.id = null;
      this.fixUser.customerId = null;
      this.fixUser.subscriptionId = null;
      this.fixUser.vegan = null;
      this.search(this.pageIndex, TABLE_NUM_ITEMS, this.searchUsers);
    },
    async openFrontendAsUser(id) {
      const headerAPI = { ApiKey: `${ApiKey}` };
      const response = await axios.post(
        `${API}/api/impersonation/${id}`,
        {},
        {
          headers: { ...headerAPI },
        }
      );
      const baseUrlFront = process.env.VUE_APP_FRONT_END_URL;
      window.open(`${baseUrlFront}/login?${response.data.query_string}`);
    },
    deleteUser() {
      store
        .dispatch("users/DeleteUser", { id: this.itemIdToDelete })
        .then(() => {
          this.delDialog = false;
        });
    },
    paginationChange(data) {
      this.pageIndex = data.event;
      this.search(
        data.event * TABLE_NUM_ITEMS,
        TABLE_NUM_ITEMS,
        this.searchIngredients,
        this.filter
      );
    },
    searchChange(evt) {
      this.searchUsers = evt;
      this.search(0, TABLE_NUM_ITEMS, evt);
    },

    async lockSubscription() {
      const headerAPI = { ApiKey: `${ApiKey}` };
      const id = this.itemIdToDelete.id;
      try {
        await axios.delete(`${API}/api/users/${id}/subscriptions/`, {
          headers: { ...headerAPI },
        });
        this.users$.map((user) => {
          if (user.id === id) {
            user.paypal_active = !user.paypal_active;
          }
        });
      } catch (e) {
        //swallow
      }
      this.lockSubscriptionDialog = false;
    },
    search(offset, limit, search) {
      store
        .dispatch("users/LoadUsers", {
          offset: offset,
          limit: limit,
          email: search,
        })
        .then(() => {});
    },
  },
};
</script>

<style></style>

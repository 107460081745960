var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { flat: "", app: "", color: "white", light: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.menu($event)
              }
            }
          }),
          _c(
            "div",
            { staticClass: "pr-2 pl-2" },
            [
              _c("v-icon", { attrs: { color: "primary" } }, [
                _vm._v("mdi-account")
              ])
            ],
            1
          ),
          _c(
            "v-toolbar-title",
            { staticStyle: { color: "#84264f", "font-weight": "bold" } },
            [
              _vm._v("\n      Usuarios\n      "),
              _c(
                "v-chip",
                { staticClass: "ml-4", attrs: { color: "primary" } },
                [_vm._v(_vm._s(_vm.totalNumUsers$))]
              )
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-btn",
            { attrs: { medium: "", color: "primary", to: "/usuarios/nuevo" } },
            [_vm._v("Añadir administrador")]
          )
        ],
        1
      ),
      _vm.error$.isError
        ? _c("v-alert", { attrs: { type: "error" } }, [
            _vm._v(_vm._s(_vm.error$.message))
          ])
        : _vm._e(),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "290" },
              model: {
                value: _vm.delDialog,
                callback: function($$v) {
                  _vm.delDialog = $$v
                },
                expression: "delDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Borrar")]),
                  _c("v-card-text", { staticClass: "subtitle-1" }, [
                    _vm._v("Deseas borrar este usuario ?")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              _vm.delDialog = false
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.deleteUser()
                            }
                          }
                        },
                        [_vm._v("Sí")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.fixUserDialog,
                callback: function($$v) {
                  _vm.fixUserDialog = $$v
                },
                expression: "fixUserDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Arreglar usuario")]),
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "10", sm: "10" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              label: "Id usuario",
                              disabled: "",
                              outlined: ""
                            },
                            model: {
                              value: _vm.fixUser.id,
                              callback: function($$v) {
                                _vm.$set(_vm.fixUser, "id", $$v)
                              },
                              expression: "fixUser.id"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "10", sm: "10" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              label: "Subscription ID",
                              outlined: ""
                            },
                            model: {
                              value: _vm.fixUser.subscriptionId,
                              callback: function($$v) {
                                _vm.$set(_vm.fixUser, "subscriptionId", $$v)
                              },
                              expression: "fixUser.subscriptionId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "10", sm: "10" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              label: "Customer ID",
                              outlined: ""
                            },
                            model: {
                              value: _vm.fixUser.customerId,
                              callback: function($$v) {
                                _vm.$set(_vm.fixUser, "customerId", $$v)
                              },
                              expression: "fixUser.customerId"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "10", sm: "10" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: [
                                { text: "Vegana", value: true },
                                { text: "Flexible", value: false }
                              ],
                              "item-text": "text",
                              "item-value": "value",
                              label: "Tipo de dieta"
                            },
                            model: {
                              value: _vm.fixUser.vegan,
                              callback: function($$v) {
                                _vm.$set(_vm.fixUser, "vegan", $$v)
                              },
                              expression: "fixUser.vegan"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              _vm.fixUserDialog = false
                              _vm.fixUser.id = null
                              _vm.fixUser.subscriptionId = null
                              _vm.fixUser.customerId = null
                              _vm.fixUser.vegan = null
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.fixUserSelected()
                            }
                          }
                        },
                        [_vm._v("Arreglar")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "290" },
              model: {
                value: _vm.lockSubscriptionDialog,
                callback: function($$v) {
                  _vm.lockSubscriptionDialog = $$v
                },
                expression: "lockSubscriptionDialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v("Borrar")]),
                  _c("v-card-text", { staticClass: "subtitle-1" }, [
                    _vm._v("¿Está seguro que desea realizar ésta acción?")
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: {
                            click: function($event) {
                              _vm.lockSubscriptionDialog = false
                            }
                          }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.lockSubscription()
                            }
                          }
                        },
                        [_vm._v("Sí")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      dense: "",
                      "prepend-inner-icon": "mdi-magnify",
                      "background-color": "red",
                      label: "Buscar usuario por email",
                      outlined: ""
                    },
                    on: { input: _vm.searchChange },
                    model: {
                      value: _vm.searchUsers,
                      callback: function($$v) {
                        _vm.searchUsers = $$v
                      },
                      expression: "searchUsers"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-toolbar",
            { attrs: { color: "secondary", dark: "", flat: "", dense: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "3" }
                    },
                    [_c("div", { staticClass: "body-1" }, [_vm._v("Nombre")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [_c("div", { staticClass: "body-1" }, [_vm._v("Tipo")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      _c("div", { staticClass: "body-1" }, [
                        _vm._v("Tipo Dieta")
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "2" }
                    },
                    [_c("div", { staticClass: "body-1" }, [_vm._v("Email")])]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      _c("div", { staticClass: "body-1" }, [
                        _vm._v("Proveedor de pago")
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [
                      _c("div", { staticClass: "body-1" }, [
                        _vm._v("Fecha de creación")
                      ])
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "text-center",
                      attrs: { cols: "12", sm: "1" }
                    },
                    [_c("div", { staticClass: "body-1" }, [_vm._v("ID Pago")])]
                  ),
                  _c("v-col", { attrs: { cols: "12", sm: "2" } }, [
                    _c("div", { staticClass: "body-1" })
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("ItemList", {
            attrs: {
              items: _vm.users$,
              actions: [
                { type: "VIEW_AS_USER", icon: "mdi-account-switch" },
                { type: "DELETE_USER", icon: "mdi-delete" },
                { type: "LOCK_PAYPAL", icon: "mdi-lock" },
                { type: "FIX_USER", icon: "mdi-auto-fix" }
              ],
              type: "user",
              dragAndDropActive: true,
              totalItems: _vm.totalNumUsers$
            },
            on: {
              actionTrigger: function($event) {
                return _vm.onActionTrigger($event)
              },
              paginationChange: function($event) {
                return _vm.paginationChange($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
<div>
      <v-app-bar flat app color="white" light>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
        <div class="pr-2 pl-2">
          <v-icon color="primary">mdi-account</v-icon>
        </div>
        <v-toolbar-title><span style="color:#84264f; font-weight:bold"> Usuarios</span> / editar usuario</v-toolbar-title>
      </v-app-bar>

      <v-alert v-if="error$.isError" type="error">
        {{ error$.message }}
      </v-alert>

      <v-snackbar v-model="updatedSuccess">
      Usuario guardado correctamente
        <v-btn
          color="pink"
          text
          @click="updatedSuccess = false">
          Cerrar
        </v-btn>
      </v-snackbar>

      <v-container fluid v-if="user">

        <v-flex xs12>
          <v-text-field v-model="user.first_name" background-color="white" label="Nombre" outlined @change="updateUser()"></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field v-model="user.last_name" background-color="white" label="Apellido" outlined @change="updateUser()"></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field disabled v-model="user.email" background-color="white" label="Email" outlined></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-select v-model="user.type" :items="userType" item-text="label" item-value="value" label="Tipo usuario" outlined @change="updateUser()"></v-select>
        </v-flex>

        <v-flex xs12>
          <v-switch color="primary" v-model="user.breakfast_included" class="ma-2" label="Desayuno incluido" @change="updateUser()"></v-switch>
        </v-flex>

        <v-flex xs12>
          <v-switch color="primary" v-model="user.snack_included" class="ma-2" label="Snack incluido" @change="updateUser()"></v-switch>
        </v-flex>

        <v-flex class="mb-6" xs12 v-if="!user.allergen">
          <div> El usuario no tiene alergias</div>
        </v-flex>

        <v-btn class="text-white" color="secondary" @click="goBack()">
          <v-icon left dark>mdi-arrow-left</v-icon>
          Volver
        </v-btn>

      </v-container>
</div>
  
</template>

<script>
import store from '../../store';
import { getUser } from '@/services/users';
import { USERS_TYPE } from '../../constants';

export default {
  data() {
    return {
      delDialog: false,
      user: null,
      userType: USERS_TYPE,
      updatedSuccess: false
    }
  },
  computed: {
    error$() {
      return store.getters['users/error'];
    }
  },
  async mounted() {
    const { data } = await getUser(this.$route.params.id);
    this.user = data[0];
  },
  methods: {
    menu() {
      store.dispatch('user/ToggleMenu');
    },
    updateUser(){
      this.$store.dispatch('users/UpdateUser', { user: this.user, id: this.$route.params.id })
        .then(() => {
          this.updatedSuccess = true;
          setTimeout(() => {
            this.updatedSuccess = false;
          }, 2000);
        })
        .catch(() => {
          alert('No ha sido posible modificar el usuario')
        })
    },
    goBack() {
      this.$router.back();
    }
  }
}
</script>

<style>

</style>
